import { useContext, useEffect, useState } from 'react'
import { cloneDeep } from 'src/helpers'
import { EVENT_LOCATION_TYPE, ILocationQuiz } from 'src/types'
import { QuizQuestionContext } from 'src/pages/quiz/contextAPI'

export const defaultLocation: ILocationQuiz = {
  type: EVENT_LOCATION_TYPE.CEREMONY,
  place: '',

  venue: '',
  city: '',
  state: '',
  streetAddress: '',
  zipCode: '',
}

export const useNewLocation = () => {
  const { data } = useContext(QuizQuestionContext)
  const [locationSelected, setLocationSelected] = useState<ILocationQuiz[]>(
    data.location ? cloneDeep(data.location) : [{ ...defaultLocation }]
  )
  const [hasValue, setHasValue] = useState(!!data.location)
  const [locationText, setLocationText] = useState('')
  // const screenWidth = window.innerWidth

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()
    const { value, name } = event.target
    const temp = [...locationSelected]
    temp[index][name as 'place'] = value

    setLocationSelected(temp)
    setHasValue(!!value)
    setLocationText(value)

    // const pacContainer = document.querySelector('.pac-container') as HTMLElement
    // const childElementCount = pacContainer.childElementCount

    // if (pacContainer && screenWidth < 768 && childElementCount) {
    //   pacContainer.style.top = `-${childElementCount === 1 ? `10.625` : `${20.625 - (5 - childElementCount) * 2.5}`}rem`
    // }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleGGPlaceSelected = (place: any, index: number) => {
    let streetNumber = '',
      route = '',
      city = '',
      state = '',
      zipCode = '',
      country = ''

    /* eslint-disable @typescript-eslint/no-explicit-any */
    place?.address_components?.forEach((component: any) => {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          streetNumber = component.long_name
          break
        }
        case 'route': {
          route = component.long_name
          break
        }
        case 'postal_code': {
          zipCode = component.long_name
          break
        }
        case 'locality':
          city = component.long_name
          break
        case 'administrative_area_level_1': {
          state = component.short_name
          break
        }
        case 'country':
          country = component.short_name
          break
      }
    })
    const temp = [...locationSelected]
    temp[index] = {
      ...temp[index],
      city,
      state,
      zipCode,
      streetAddress: `${streetNumber} ${route}`,
      place: [place?.name, place?.formatted_address].filter((i) => i).join(', ') || '',
      venue: place?.name || '',
      placeId: place.place_id,
      country,
    }
    setLocationSelected(temp)
  }

  const handleBlur = () => {
    const forAvailabilityElement = document.querySelector('.input-wrapper.for-availability') as HTMLElement
    if (forAvailabilityElement) {
      forAvailabilityElement.style.marginBottom = '0px'
    }
  }
  const handleFocus = () => {
    const forAvailabilityElement = document.querySelector('.input-wrapper.for-availability') as HTMLElement
    if (forAvailabilityElement) {
      forAvailabilityElement.style.marginBottom = '250px'
    }
  }

  useEffect(() => {
    const currentTempData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const currentLocationDataFromStore = currentTempData?.eventVenues?.find(
      (e: any) => e.type === EVENT_LOCATION_TYPE.CEREMONY
    )
    if (currentLocationDataFromStore) {
      setLocationSelected([currentLocationDataFromStore])
      setLocationText(currentLocationDataFromStore.place)
      setHasValue(true)
    } else {
      if (Array.isArray(data.location) && data.location.length > 0) {
        setLocationSelected(cloneDeep(data.location))
        setHasValue(data.location.some((loc) => !!loc.place))
      }
    }
  }, [data.location])

  return {
    locationSelected,
    setLocationSelected,
    hasValue,
    handleChange,
    handleGGPlaceSelected,
    handleBlur,
    handleFocus,
    locationText,
  }
}
